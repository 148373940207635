<template>
  <div class=" container-fluid container-fixed-lg bg-white app-table-container">
    <ConfirmModal ref="modal_delete_entry" :modal-header-title="$t('common.delete confirm title')"
                  :confirm-handle="deleteEntrySubmit">
      <template v-slot:body>
        <p class="confirm-body">{{ $t('common.delete confirm') }}</p>
      </template>
    </ConfirmModal>
    <div class="card card-transparent">
      <div class="card-header search-container" v-if="tools.search.searchAll || hasFiltersUi">
        <div class="search-content">
          <ValidationObserver v-slot="{ passes, valid, validated }">
            <form novalidate autocomplete="off" @submit.prevent="passes(searchHandle)">
              <div v-if="tools.search.searchAll && tools.search.filters.length === 0 ||
              !tools.search.searchAll && tools.search.filters.length === 1" class="row">
                <div class="d-flex justify-content-start col-md-12">
                  <div class="w-25">
                    <AppInput input-style="normal" v-if="tools.search.searchAll" v-model="pagination.search"/>
                    <template v-for="(filter, index) in tools.search.filters">
                      <template v-if="filter.uiType === 'input'">
                        <AppInput :name="filter.label" :label="filter.label" input-style="normal"
                                  v-model="tools.search.filters[index].value"/>
                      </template>
                      <template v-else-if="filter.uiType === 'select'">
                        <AppSelect :options="{containerCssClass: 'z-index-0'}" input-style="normal" :name="filter.name"
                                   :label="filter.label"
                                   :options-data="filter.options"
                                   v-model="tools.search.filters[index].value"/>
                      </template>
                      <template v-else-if="filter.uiType === 'datepicker'">
                        <AppDatePicker input-style="normal" :name="filter.label" :label="filter.label"
                                       v-model="tools.search.filters[index].value"/>
                      </template>
                    </template>
                  </div>
                  <div class="m-t-auto m-b-15">
                    <button class="btn btn-default text-complete m-l-10 m-r-10" type="submit">
                      {{ $t('common.search') }}
                      <i
                        class="fa fa-search"></i>
                    </button>
                    <button v-if="tools.search.reset" @click="resetAllSearch"
                            class="btn btn-default m-r-10" type="button">
                      {{ $t('common.reset') }} <i class="fa fa-refresh"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div v-if="tools.search.searchAll" class="col-lg-3 col-md-4 col-sm-6">
                    <AppInput :label="$t('common.text search')" type="text" v-model="pagination.search"/>
                  </div>
                  <div v-for="(filter, index) in tools.search.filters"
                       :class="[filter.boundaryClass, 'col-lg-3 col-md-4 col-sm-6']" v-if="filter.uiType">
                    <template v-if="filter.uiType === 'input'">
                      <AppInput :name="filter.name"
                                :label="filter.label"
                                input-style="normal"
                                :type="filter.inputType ? filter.inputType : false"
                                label-class="text-complete"
                                v-model="tools.search.filters[index].value"/>
                    </template>
                    <template v-else-if="filter.uiType === 'select'">
                      <AppSelect :options="{containerCssClass: 'z-index-0', placeholder: filter.label}"
                                 input-style="normal" :name="filter.name"
                                 :label="filter.label"
                                 label-class="text-complete"
                                 :options-data="filter.options"
                                 v-model="tools.search.filters[index].value"/>
                    </template>
                    <template v-else-if="filter.uiType === 'datepicker'">
                      <AppDatePicker :rules="filter.rules ? filter.rules : ''"
                                     input-style="normal" :name="filter.name"
                                     :label="filter.label"
                                     label-class="text-complete"
                                     v-model="tools.search.filters[index].value"/>
                    </template>
                    <template v-else-if="filter.uiType === 'datepicker_from'">
                      <div class="row">
                        <div class="col-md-5-5">
                          <AppDatePicker :rules="filter.rules ? filter.rules : ''"
                                         input-style="normal" :name="filter.name"
                                         :label="filter.label"
                                         label-class="text-complete"
                                         v-model="tools.search.filters[index].value"/>
                        </div>
                        <div class="col-md-1 p-0 m-0">
                          <div class="form-group app-form-group b-b-0">
                            <label class="input-label control-label p-0 p-r-5 visible-none">
                              none
                            </label>
                            <div class="form-group m-b-0 p-0 text-center" style="height: 50px; line-height: 50px">
                              ～
                            </div>
                          </div>
                        </div>
                        <div class="col-md-5-5">
                          <AppDatePicker
                            :rules="tools.search.filters[index+1].rules ? tools.search.filters[index+1].rules : ''"
                            input-style="normal"
                            label="none"
                            :name="tools.search.filters[index+1].name"
                            label-class=" visible-none"
                            v-model="tools.search.filters[index+1].value"/>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="m-t-10 row justify-content-center">
                  <button v-if="tools.search.reset" @click="resetAllSearch"
                          class="btn btn-default m-r-10" type="button">
                    {{ $t('common.reset') }}
                  </button>
                  <button :disabled="!valid && validated" class="btn btn-default text-complete m-r-10" type="submit">
                    {{ $t('common.search') }}
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div class="card-header d-flex justify-content-between">
        <div class="card-title table-data-info">
          <div class="dataTables_info app-text" role="status" aria-live="polite">
            全{{ meta.total }}件中 {{ meta.from }}件 〜 {{ meta.to }}件 を表示
          </div>
        </div>
        <div class="export-options-container">
          <div class="exportOptions">
            <div class="btn-group">
              <div>
                <button v-if="actions.exportEntries" @click="() => actions.exportEntries()"
                        class="btn btn-default app-text">
                  {{ $t('common.download csv') }}
                </button>
                <button v-if="actions.importEntries" @click="() => actions.importEntries()"
                        class="btn btn-default btn-export">
                  {{ $t('common.import csv') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="export-options-container">
          <div class="exportOptions">
            <div class="btn-group">
              <div>
                <button v-if="actions.createEntry" @click="() => actions.createEntry()" class="btn btn-create">
                  {{ $t('common.create') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="dataTables_wrapper no-footer bg-white">
          <div class="table-responsive sm-m-b-15">
            <Modal modal-class="slide-up" ref="detail_view_entry" size="lg"
                   :modal-header-title="$t('common.information of', {entry: entrySelectedView[detail.headerColumn]})">
              <template v-slot:container>
                <div class="modal-body border-bottom-0">
                  <div class="table-responsive">
                    <div class="dataTables_wrapper no-footer">
                      <table class="table table-hover table-condensed table-detailed dataTable no-footer"
                             role="grid">
                        <tbody>
                        <tr v-for="column in columns" v-if="column.detailShow">
                          <td style="width: 180px;">{{ column.label }}</td>
                          <td v-if="column.computed" style="width: 300px;">
                            {{ column.computed(entrySelectedView) }}
                          </td>
                          <td v-else style="width: 300px;">{{ _.get(entrySelectedView, column.name) }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
            </Modal>
            <table :class="[entries.length ? '' : 'table-empty-data', 'table dataTable app-table no-footer']">
              <thead>
              <tr>
                <th class="text-center cell-fit-content">
                  No.
                </th>
                <template v-for="column in columns" v-if="column.listShow">
                  <th v-if="column.sortable" @click="toggleSortBy(column.name)"
                      :class="[
                    pagination.sortBy === column.name ? (pagination.descending ? 'sorting_desc' : 'sorting_asc') : 'sorting',
                     'text-center',
                     column.fitSize ? 'cell-fit-content' : ''
                     ]">
                    {{ column.label }}
                  </th>
                  <th v-else
                      :class="[ 'text-center', column.fitSize ? 'cell-fit-content' : '']">
                    {{ column.label }}
                  </th>
                </template>
                <th class="text-center cell-fit-content" v-if="actions.viewEntry">{{
                    $t('common.view')
                  }}
                </th>
                <th class="text-center cell-fit-content" v-if="actions.editEntry">{{
                    $t('common.edit')
                  }}
                </th>
                <th class="text-center cell-fit-content" v-if="actions.deleteEntry || actions.deleteEntryCondition">{{
                    $t('common.delete')
                  }}
                </th>
                <th class="text-center cell-fit-content" v-for="action in actions.others">
                  {{ action.label }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!entries.length">
                <td colspan="100">
                  <span v-if="loading">
                    {{ $t('common.loading') }}
                  </span>
                  <span v-else>
                    {{ $t('common.list empty') }}
                  </span>
                </td>
              </tr>
              <tr v-for="entry in entries">
                <td class="app-table-p v-align-middle text-center">
                  {{ entry.no }}
                </td>
                <td v-for="column in columns" v-if="column.listShow" :class="['v-align-middle', column.class]">
                  <p v-if="column.computedHtml" class="app-table-p app-cell-tooltip">
                    <span v-html="column.computedHtml(entry)"></span>
                  </p>
                  <p v-else :data-original-title="column.computed ? column.computed(entry) : _.get(entry, column.name)"
                     class="app-table-p app-cell-tooltip">
                    <span>{{ column.computed ? column.computed(entry) : _.get(entry, column.name) }}</span>
                  </p>
                </td>
                <td class="v-align-middle" v-if="actions.viewEntry">
                  <button @click="() => viewEntry(entry)" type="button"
                          class="btn btn-default btn-rounded"><i
                    class="fa fa-eye"></i>
                  </button>
                </td>
                <td class="v-align-middle" v-if="actions.editEntry">
                  <div @click="() => actions.editEntry(entry)"
                       class="btn-tool edit">
                  </div>
                </td>
                <td class="v-align-middle" v-if="actions.deleteEntry || actions.deleteEntryCondition">
                  <div
                    v-if="!actions.deleteEntryCondition || actions.deleteEntryCondition && actions.deleteEntryCondition(entry) "
                    @click="deleteEntry(entry)" class="btn-tool delete">
                  </div>
                </td>
                <td class="v-align-middle" v-for="action in actions.others">
                  <template v-if="action.showCondition ? action.showCondition(entry) : true">
                    <template v-if="action.click">
                      <span v-html="action.contentHtml(entry)" @click="action.click(entry)"></span>
                    </template>
                    <template v-else-if="action.routerLink">
                      <router-link :to="action.routerLink(entry)">
                        <span v-html="action.contentHtml(entry)"></span>
                      </router-link>
                    </template>
                    <template v-else>
                      <span v-html="action.contentHtml(entry)"></span>
                    </template>
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="d-flex justify-content-between">
              <div class="dataTables_paginate app-paginate-container">
                <AppPaginate
                  v-show="parseInt(meta.last_page) > 1"
                  ref="paginate"
                  :page-count="parseInt(meta.last_page)"
                  :page-range="5"
                  :margin-pages="2"
                  :click-handler="clickPagination"
                  prev-text="<"
                  next-text=">"
                  breakViewClass="paginate-break"
                  prev-class="paginate-button-prev"
                  next-class="paginate-button-next"
                  :force-page="parseInt(pagination.currentPage)"
                  :hide-prev-next="true"
                  :page-class="'paginate-button'">
                </AppPaginate>
              </div>
              <div v-show="parseInt(meta.total) > 5"
                   class="d-flex justify-content-start padding-20 form-group dataTable_info_custom">
                <div class="m-r-5 m-t-5 perpage-label"> {{ $t('common.perpage') }}
                </div>
                <div class="form-group">
                  <select class="form-control" @change="changePerPage"
                          v-model="pagination.currentPerPage">
                    <option v-for="perpage in pagination.perPages" :value="perpage">
                      {{ perpage }}
                    </option>
                  </select></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const EVENT_PAGE_CHANGE = 'EVENT_PAGE_CHANGE';
const EVENT_SORT_BY = 'EVENT_SORT_BY';
const EVENT_SEARCH = 'EVENT_SEARCH';
const EVENT_PER_PAGE_CHANGE = 'EVENT_PER_PAGE_CHANGE';

export default {
  props: {
    settingColumns: Array,
    //Example array of {name: 'email', label: 'Email', detailHeader: true, computed: (val) => {return val}},
    actions: Object,
    otherActions: Object,
    settingTools: {
      type: Object,
      default: {}
    },
    settingApis: {},
    tableName: String,
  },
  data() {
    var detailHeader = 'id';
    const columns = this.settingColumns.map(val => {
      if (val.detailHeader) {
        detailHeader = val.name
      }
      return {
        sortable: true,
        listShow: true,
        detailShow: true,
        editable: true,
        label: val.label === undefined ? val.name.charAt(0).toUpperCase() + val.name.slice(1) : val.label,
        ...val
      }
    })
    var tools = this.settingTools;
    if (!tools.search) {
      tools = {
        ...tools,
        search: {
          searchAll: false,
          filters: [],
        },
        meta: {
          from: 0,
          to: 0,
        },
      }
    }
    _.forEach(this.$route.query, (value, columnString) => {
      if (_.includes(columnString, 'filters.')) {
        let splited = columnString.split('.');
        let name = splited[1];
        let type = splited[2];
        _.forEach(tools.search.filters, (filter, index) => {
          if (filter.name === name && filter.type === type) {
            tools.search.filters[index] = {...tools.search.filters[index], value: value}
          }
        })
      }
    })
    const {page, search, sortBy, sortType, perPage} = this.$route.query;

    return {
      entriesRes: {},
      entries: [],
      detail: {
        headerColumn: detailHeader
      },
      loading: true,
      meta: {},
      tools: tools,
      pagination: {
        currentPage: page ? page : 1,
        search: search ? search : '',
        sortBy: sortBy ? sortBy : null,
        descending: sortType === 'desc',
        currentPerPage: perPage ? perPage : 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
      },
      columns: columns,
      entrySelectedDelete: {},
      entrySelectedView: {},
      columnsSize: _.size(columns) + (this.otherActions ? _.size(this.otherActions) : 0),
    }
  },
  watch: {
    "$route.query": {
      handler: function (after, before) {
        if (!_.isEqual(after, before)) {
          this.setSearchStateByRouteQuery()
          this.getEntries()
        }
      },
      deep: true,
    }
  },
  computed: {
    hasFiltersUi() {
      var hasFiltersUi = false;
      _.forEach(this.tools.search.filters, filter => {
        if (filter.uiType) {
          hasFiltersUi = true;
        }
      })
      return hasFiltersUi;
    },
  },
  mounted() {
    this.getEntries()
  },
  methods: {
    clickPagination(selectPage) {
      if (selectPage !== this.pagination.currentPage) {
        this.setCurrentPage(selectPage)
        this.updateRouteQuery(EVENT_PAGE_CHANGE)
      }
    },
    changePerPage() {
      this.setCurrentPage(1)
      this.updateRouteQuery([EVENT_PAGE_CHANGE, EVENT_PER_PAGE_CHANGE])
    },
    setCurrentPage(page) {
      this.pagination.currentPage = page;
      if (this.$refs.paginate) {
        this.$refs.paginate.handlePageSelected(page);
      }
    },
    searchHandle() {
      this.setCurrentPage(1)
      this.updateRouteQuery([EVENT_PAGE_CHANGE, EVENT_SEARCH])
    },
    async getEntries() {
      const {currentPage, search, sortBy, descending, currentPerPage} = this.pagination
      let params = {
        page: currentPage,
        perPage: currentPerPage,
        search: search,
        sortBy: sortBy,
        sortType: descending ? 'desc' : 'asc',
      }
      const filters = this.tools.search.filters
      _.forEach(filters, (filter) => {
        if (filter.value && filter.value.toString().length) {
          params[`filters[${filter.name}][${filter.type}]`] = filter.value
        }
      })
      let endpoint = this.settingApis.list.endpoint;
      let apiParams = this.settingApis.list.params;
      if (!apiParams) {
        apiParams = {}
      }
      const res = await this.$request.get(endpoint, {...params, ...apiParams})
      if (!res.hasErrors()) {
        this.entriesRes = _.cloneDeep(res);
        this.entries = res.data.data.map((entry, index) => {
          return {
            ...entry, no: (index + 1 + (this.pagination.currentPage - 1) * this.pagination.currentPerPage)
          }
        })
        this.meta = res.data.meta
        if (!this.meta.from) {
          this.meta.from = 0;
        }
        if (!this.meta.to) {
          this.meta.to = 0;
        }
      }
      if (this.pagination.currentPage > 1 && this.entries.length === 0) {
        this.setCurrentPage(1)
        this.updateRouteQuery(EVENT_PAGE_CHANGE)
      }
      this.$nextTick(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          if (el.offsetWidth < el.scrollWidth) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      });
      this.loading = false;
    },
    setSearchStateByRouteQuery() {
      const {page, search, sortBy, sortType, perPage} = this.$route.query;
      this.pagination = {
        currentPage: page ? page : 1,
        search: search ? search : '',
        sortBy: sortBy ? sortBy : null,
        descending: sortType === 'desc',
        currentPerPage: perPage ? perPage : 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
      }
      var newTools = _.cloneDeep(this.tools)
      _.forEach(newTools.search.filters, (filter, index) => {
        newTools.search.filters[index] = {...filter, value: null}
      })
      _.forEach(this.$route.query, (value, columnString) => {
        if (_.includes(columnString, 'filters.')) {
          let splited = columnString.split('.');
          let name = splited[1];
          let type = splited[2];
          _.forEach(newTools.search.filters, (filter, index) => {
            if (filter.name === name && filter.type === type) {
              newTools.search.filters[index] = {...newTools.search.filters[index], value: value}
            }
          })
        }
      })
      this.tools = newTools
    },
    resetAllSearch() {
      if (Object.keys(this.$route.query).length === 0) {
        this.setSearchStateByRouteQuery()
        this.getEntries()
      } else {
        this.$router.push({name: this.$route.name, query: {}})
      }
    },
    toggleSortBy(columnName) {
      if (this.pagination.sortBy !== columnName) {
        this.pagination.sortBy = columnName
        this.pagination.descending = false;
      } else {
        if (!this.pagination.descending) {
          this.pagination.sortBy = columnName
          this.pagination.descending = true;
        } else {
          this.pagination.sortBy = null
        }
      }
      this.updateRouteQuery(EVENT_SORT_BY);
    },
    updateRouteQuery(events) {
      if (!_.isArray(events)) {
        events = [events]
      }
      var queryObj = _.clone(this.$route.query);
      var {currentPage, search, sortBy, descending, currentPerPage} = this.pagination
      _.forEach(events, event => {
        if (event === EVENT_PAGE_CHANGE) {
          if (currentPage > 1) {
            queryObj['page'] = currentPage
          } else {
            delete queryObj['page']
          }
        }
        if (event === EVENT_PER_PAGE_CHANGE) {
          if (parseInt(currentPerPage) !== 10) {
            queryObj['perPage'] = currentPerPage;
          } else {
            delete queryObj['perPage']
          }
        }
        if (event === EVENT_SORT_BY) {
          if (sortBy && sortBy.length) {
            queryObj['sortBy'] = sortBy;
          } else {
            delete queryObj['sortBy'];
          }
          if (sortBy && sortBy.length) {
            queryObj['sortType'] = descending ? 'desc' : 'asc';
          } else {
            delete queryObj['sortType'];
          }
        }
        if (event === EVENT_SEARCH) {
          if (search && search.length) {
            queryObj['search'] = search;
          } else {
            delete queryObj['search'];
          }
          const filters = this.tools.search.filters;
          _.forEach(filters, (filter) => {
            if (filter.value && filter.value.toString().length) {
              queryObj['filters.' + filter.name + '.' + filter.type] = filter.value
            } else {
              delete queryObj['filters.' + filter.name + '.' + filter.type];
            }
          })
        }
      })
      if (!_.isEqual(queryObj, this.$route.query)) {
        this.$router.push({query: queryObj})
      } else {
        this.getEntries()
      }
    },
    viewEntry(entry) {
      this.entrySelectedView = entry;
      this.$refs.detail_view_entry.show()
    },

    /* DELETE entry start */
    async deleteEntrySubmit() {
      const endpoint = this.settingApis.delete.endpoint;
      let params = this.settingApis.delete.params;
      if (!params) {
        params = {};
      }
      const res = await this.$request.delete(endpoint(this.entrySelectedDelete.id), params)
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg delete ok'))
        this.$refs.modal_delete_entry.hide();
        this.getEntries()
      } else {
        this.$appNotice.error(res.data?.error_msg)
      }
    },
    deleteEntry(entry) {
      this.entrySelectedDelete = entry;
      this.$refs.modal_delete_entry.show();
    }
    /* DELETE entry end */
  }
};
</script>
